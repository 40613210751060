@tailwind base;
@tailwind components;
@tailwind utilities;

.logo_text {
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to bottom right, rgb(17, 52, 93), rgb(95, 144, 201));
    font-weight: 600;
    font-style: italic;
  }
  /* .hero{ background-image: "url('/img/hero-bg.svg')" } */

  a[href^="http://"]:not([href*="swap.softwarematch.de"]):after,
  a[href^="https://"]:not([href*="swap.softwarematch.de"]):after {
  /* content: url('img/external.svg'); */
  vertical-align:unset;
  padding-left:4px;
}