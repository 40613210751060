.tilt-box-wrap{
	transition: all 0.3s ease-out;
	perspective: 1000px
}
.tilt-box-wrap:hover{
	transition: all 0.3s linear;
	transform: scale(1.1);
}
.tilt-box{
	transition: all 0.3s ease-out;
	transform:rotateX(0deg) rotateY(0deg);
	perspective: 1000px;
	transform-style: preserve-3d;
}
.tilt-box strong{
	position: absolute;
	transform:translateZ(40px);
}
.hover_area{
	width: 33.333%;
	height: 33.333%;
	position: absolute;
	z-index: 1;
}

.hover_area:nth-child(1){ left: 0; top: 0; }
.hover_area:nth-child(2){ left: 33.333%; top: 0; }
.hover_area:nth-child(3){ left: 66.666%; top: 0; }
.hover_area:nth-child(4){ left: 0; top: 33.333%; }
.hover_area:nth-child(5){ left: 33.333%; top: 33.333%; }
.hover_area:nth-child(6){ left: 66.666%; top: 33.333%; }
.hover_area:nth-child(7){ left: 0; top: 66.666%; }
.hover_area:nth-child(8){ left: 33.333%; top: 66.666%; }
.hover_area:nth-child(9){ left: 66.666%; top: 66.666%; }

.hover_area:nth-child(1):hover ~ .tilt-box{transform:rotateX(-10deg) rotateY(10deg);}
.hover_area:nth-child(2):hover ~ .tilt-box{transform: rotateX(-10deg) rotateY(0deg)}
.hover_area:nth-child(3):hover ~ .tilt-box{transform: rotateX(-10deg) rotateY(-10deg)}
.hover_area:nth-child(4):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(10deg)}
.hover_area:nth-child(5):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(0deg)}
.hover_area:nth-child(6):hover ~ .tilt-box{transform: rotateX(0deg) rotateY(-10deg)}
.hover_area:nth-child(7):hover ~ .tilt-box{transform:rotateX(10deg) rotateY(10deg);}
.hover_area:nth-child(8):hover ~ .tilt-box{transform: rotateX(10deg) rotateY(0deg)}
.hover_area:nth-child(9):hover ~ .tilt-box{transform: rotateX(10deg) rotateY(-10deg)}