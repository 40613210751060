@keyframes blink {
    0%, 100% { opacity: 1; }
    50% { opacity: 0; }
  }
  
  .typewriter {
    --caret: currentcolor;
  }
  
  .typewriter::after {
    content: "";
    border-right: 1px solid var(--caret);
    animation: blink 0.5s linear infinite;
  }
  
  .typewriter.thick::after {
    border-right: 1ch solid var(--caret);
  }
  
  .typewriter.nocaret::after {
    border-right: 0;
  }
  
  
  @media (prefers-reduced-motion) {
    .typewriter::after {
      animation: none;
    }
  }
  
  /**/
  